import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {hierarchicalMenu, Link} from "../../plugins/gatsby-plugin-atollon";
import Container from "./primitives/grid/container";
import Background from "./primitives/background";
import Typography from "./primitives/typography";
import Slogan from "../assets/images/slogan.svg"
import {colors} from "./primitives/colors";
import {motion} from "framer-motion";
import Wordmark from "../assets/images/wordmark.svg"
import {breakpoints} from "./primitives/tokens";
import Input from "./primitives/input";
import Button from "./primitives/button";
import FormProvider from "./primitives/form-provider";
import FadeTransitionFramer from "./animations/transitions/fade-transition-framer";
import {useCompareContext} from "../contexts/compare-context";
import useDynamicImports, {LoadingStrategy} from "../hooks/use-dynamic-imports";

const Footer = () => {

    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)

    const {
        setIsOpen,
        items,
    } = useCompareContext()

    const {
        hasLoaded,
        scripts: {
            ConfettiExplosion
        },
        load,
    } = useDynamicImports({
        ConfettiExplosion: () => import("react-confetti-explosion")
    }, LoadingStrategy.Interaction)

    const {
        allDatoCmsFooterMenuItem: {
            nodes: footerItems
        },
        datoCmsSiteOptionPage: {
            phoneNumber,
            emailAddress,
            privacyPolicyFooterLink,
            termsAndConditionsFooterLink
        }
    } = useStaticQuery(graphql`
        query FooterItems {
            datoCmsSiteOptionPage {
                phoneNumber
                emailAddress
                privacyPolicyFooterLink {
                    ...LinkWithTitleProps
                }
                termsAndConditionsFooterLink {
                    ...LinkWithTitleProps
                }
            }
            allDatoCmsFooterMenuItem {
                nodes {
                    id
                    title
                    link {
                        ...LinkWithoutTitleProps
                    }
                    position
                    treeParent {
                        id
                    }
                }
            }
        }
    `)
    const menuItems = hierarchicalMenu(footerItems);


    const submitSignup = async ({ email }) => {
        load()
        try {
            setLoading(true)

            const smsbump_request = {
                "phone":"",
                "email":email,
                "country":"AU",
                "form_id":322320,
                "customer_id":0,
                "loyalty_app":null,
                "timezone":Intl.DateTimeFormat().resolvedOptions().timeZone,
                "resubscribe_confirm":false,
                "from_page":window.location.href,
                "step_subscribe_request_id":0
            }

            const res = await fetch('https://api.smsbump.com/v2/formsPublic/subscribe', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Smsbump-Platform': 'shopify',
                },
                body: JSON.stringify(smsbump_request)
            })

            const r = await res.text()
            console.log(r)


            setLoading(false)
            setSuccess(true)
        } catch (e) {
            setLoading(false)
        }
    }

  return (
    <footer css={{
        margin: '28px 0',
        overflow: 'hidden',
        maxWidth: '100%',
        position: 'relative',
        '.no-menu &': {
            display: 'none'
        },
        '.customizer &': {
            display: 'none'
        },
        [breakpoints.mb]: {
            marginTop: '16px',
            marginBottom: '20px'
        }
    }}>
        <Container>
            <Background backgroundColor="secondaryBeige2" css={{
                padding: '32px 28px',
                [breakpoints.mb]: {
                    padding: '24px 20px'
                }
            }}>
                <div css={{
                    marginBottom: '164px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    [breakpoints.mb]: {
                        marginBottom: '48px',
                        flexDirection: 'column-reverse'
                    }
                }}>
                    <div css={{
                        marginTop: '44px',
                        display: 'none',
                        [breakpoints.mb]: {
                            display: 'block',
                            marginTop: '48px',
                        }
                    }}>
                        {phoneNumber && (
                            <a href={`tel:${phoneNumber}`} css={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4044_16576)">
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4044_16576">
                                            <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <Typography fontSize={{
                                    dt: "s1",
                                    mb: "p1"
                                }} css={{
                                    marginLeft: '10px'
                                }} color="neutral10">
                                    {phoneNumber}
                                </Typography>
                            </a>
                        )}
                        <a href={`mailto:${emailAddress}`} css={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '3px'
                        }}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-1-inside-1_4044_9320" fill="white">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.25 0.900391H0.709961V16.2268H9.13725V20.4377L13.7311 16.2268H19.25V11.1678L19.25 11.1677H19.25V0.900391Z"/>
                                </mask>
                                <path d="M0.709961 0.900391V0.190391H-3.9041e-05V0.900391H0.709961ZM19.25 0.900391H19.96V0.190391H19.25V0.900391ZM0.709961 16.2268H-3.9041e-05V16.9368H0.709961V16.2268ZM9.13725 16.2268H9.84725V15.5168H9.13725V16.2268ZM9.13725 20.4377H8.42725V22.0517L9.61702 20.9611L9.13725 20.4377ZM13.7311 16.2268V15.5168H13.4549L13.2513 15.7034L13.7311 16.2268ZM19.25 16.2268V16.9368H19.96V16.2268H19.25ZM19.25 11.1678L18.775 10.64L18.54 10.8516V11.1678H19.25ZM19.25 11.1677L19.7249 11.6955L21.1002 10.4577H19.25V11.1677ZM19.25 11.1677H18.54V11.8777H19.25V11.1677ZM0.709961 1.61039H19.25V0.190391H0.709961V1.61039ZM1.41996 16.2268V0.900391H-3.9041e-05V16.2268H1.41996ZM9.13725 15.5168H0.709961V16.9368H9.13725V15.5168ZM9.84725 20.4377V16.2268H8.42725V20.4377H9.84725ZM13.2513 15.7034L8.65749 19.9144L9.61702 20.9611L14.2108 16.7501L13.2513 15.7034ZM19.25 15.5168H13.7311V16.9368H19.25V15.5168ZM18.54 11.1678V16.2268H19.96V11.1678H18.54ZM18.775 10.64L18.775 10.64L19.7249 11.6955L19.7249 11.6955L18.775 10.64ZM19.25 11.8777H19.25V10.4577H19.25V11.8777ZM18.54 0.900391V11.1677H19.96V0.900391H18.54Z" fill="#1A1A1A" mask="url(#path-1-inside-1_4044_9320)"/>
                            </svg>
                            <Typography fontSize={{
                                dt: "s1",
                                mb: "p1",
                            }} css={{
                                marginLeft: '10px'
                            }} color="neutral10">
                                {emailAddress}
                            </Typography>
                        </a>
                    </div>
                    <ul css={{
                        display: 'flex',
                        gap: '28px',
                        maxWidth: '643px',
                        width: '100%',
                        justifyContent: 'space-between',
                        listStyleType: 'none',
                        marginRight: '28px',
                        [breakpoints.mb]: {
                            flexWrap: 'wrap',
                            marginRight: '0'
                        }
                    }}>
                        {menuItems.childMenu.map((item) => (
                            <li css={{
                                [breakpoints.mb]: {
                                    width: 'calc(50% - 14px)',
                                }
                            }}>
                                <Typography fontSize={{
                                    dt: 's1'
                                }} css={{
                                    marginBottom: '20px'
                                }}>{item.title}</Typography>
                                <ul css={{
                                    listStyleType: 'none'
                                }}>
                                    {item.childMenu.map((item) => (
                                        <Link css={{
                                            marginBottom: '2px'
                                        }} {...item.link}>
                                            <Typography fontSize={{
                                                dt: 'p'
                                            }} fontWeight="light">
                                                {item.title}
                                            </Typography>
                                        </Link>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <div css={{
                        maxWidth: '442px',
                        width: '100%',
                        [breakpoints.mb]: {
                            marginBottom: '48px'
                        }
                    }}>
                        <Typography fontSize={{
                            dt: 'p1'
                        }} css={{
                            marginBottom: '20px'
                        }}>
                            Join the Shadey mailing list for subscriber only offers, discounts and exclusives.
                        </Typography>
                        <FadeTransitionFramer shouldChange={success.toString()}>
                            {!success && (
                                <FormProvider css={{
                                    position: 'relative'
                                }} onSubmit={submitSignup}>
                                    <Input hideLabel css={{
                                        backgroundColor: colors.secondaryBeige,
                                        borderRadius: '500px',
                                        height: '46px',
                                        padding: '10px 24px',
                                        width: '100%'
                                    }} name="email" required type="email" placeholder="Email Address" />
                                    <Button loading={loading} type="submit" css={{
                                        position: 'absolute',
                                        right: '24px',
                                        top: '12px'
                                    }}>
                                        <Typography fontSize={{
                                            dt: "p1"
                                        }} color="neutral9">
                                            Sign up
                                        </Typography>
                                    </Button>
                                </FormProvider>
                            )}
                            {(success && hasLoaded) && (<Background backgroundColor="secondaryBeige3" css={{
                                padding: '24px'
                            }}>
                                <ConfettiExplosion />
                                <Typography fontSize={{
                                    dt: "h4"
                                }} color="neutral10" css={{
                                    marginBottom: '8px'
                                }}>
                                    Success!
                                </Typography>
                                <Typography fontSize={{
                                    dt: "p1"
                                }} fontWeight="light" color="neutral10">
                                    You’ve been subscribed to the Shadey mailing list.
                                    You can change your email preferences any time.
                                </Typography>
                            </Background>)}
                        </FadeTransitionFramer>
                        <div css={{
                            marginTop: '44px',
                            display: 'block',
                            [breakpoints.mb]: {
                                marginTop: '48px',
                                display: 'none'
                            }
                        }}>
                            {phoneNumber && (
                                <a href={`tel:${phoneNumber}`} css={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4044_16576)">
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.11765 1H7.35294L9.47059 6.29412L6.82353 7.88235C7.95749 10.1816 9.81838 12.0425 12.1176 13.1765L13.7059 10.5294L19 12.6471V16.8824C19 17.444 18.7769 17.9826 18.3798 18.3798C17.9826 18.7769 17.444 19 16.8824 19C12.7522 18.749 8.85663 16.9951 5.93076 14.0692C3.00489 11.1434 1.25099 7.24784 1 3.11765C1 2.55601 1.22311 2.01738 1.62024 1.62024C2.01738 1.22311 2.55601 1 3.11765 1Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.71" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4044_16576">
                                                <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Typography fontSize={{
                                        dt: "s1"
                                    }} css={{
                                        marginLeft: '10px'
                                    }} color="neutral10">
                                        {phoneNumber}
                                    </Typography>
                                </a>
                            )}
                            <a href={`mailto:${emailAddress}`} css={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '2px'
                            }}>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-inside-1_4044_9320" fill="white">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.25 0.900391H0.709961V16.2268H9.13725V20.4377L13.7311 16.2268H19.25V11.1678L19.25 11.1677H19.25V0.900391Z"/>
                                    </mask>
                                    <path d="M0.709961 0.900391V0.190391H-3.9041e-05V0.900391H0.709961ZM19.25 0.900391H19.96V0.190391H19.25V0.900391ZM0.709961 16.2268H-3.9041e-05V16.9368H0.709961V16.2268ZM9.13725 16.2268H9.84725V15.5168H9.13725V16.2268ZM9.13725 20.4377H8.42725V22.0517L9.61702 20.9611L9.13725 20.4377ZM13.7311 16.2268V15.5168H13.4549L13.2513 15.7034L13.7311 16.2268ZM19.25 16.2268V16.9368H19.96V16.2268H19.25ZM19.25 11.1678L18.775 10.64L18.54 10.8516V11.1678H19.25ZM19.25 11.1677L19.7249 11.6955L21.1002 10.4577H19.25V11.1677ZM19.25 11.1677H18.54V11.8777H19.25V11.1677ZM0.709961 1.61039H19.25V0.190391H0.709961V1.61039ZM1.41996 16.2268V0.900391H-3.9041e-05V16.2268H1.41996ZM9.13725 15.5168H0.709961V16.9368H9.13725V15.5168ZM9.84725 20.4377V16.2268H8.42725V20.4377H9.84725ZM13.2513 15.7034L8.65749 19.9144L9.61702 20.9611L14.2108 16.7501L13.2513 15.7034ZM19.25 15.5168H13.7311V16.9368H19.25V15.5168ZM18.54 11.1678V16.2268H19.96V11.1678H18.54ZM18.775 10.64L18.775 10.64L19.7249 11.6955L19.7249 11.6955L18.775 10.64ZM19.25 11.8777H19.25V10.4577H19.25V11.8777ZM18.54 0.900391V11.1677H19.96V0.900391H18.54Z" fill="#1A1A1A" mask="url(#path-1-inside-1_4044_9320)"/>
                                </svg>
                                <Typography fontSize={{
                                    dt: "s1"
                                }} css={{
                                    marginLeft: '10px'
                                }} color="neutral10">
                                    {emailAddress}
                                </Typography>
                            </a>
                        </div>
                    </div>
                </div>
                <Wordmark css={{
                    width: 'min(655px, 100%)'
                }}/>
                <div css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '20px',
                    [breakpoints.mb]: {
                        marginTop: '8px',
                        maxWidth: '243px'
                    }
                }}>
                    <Slogan />
                    <div css={{
                        [breakpoints.mb]: {
                            display: 'none'
                        }
                    }}>
                        <div css={{
                            display: 'flex',
                            gap: '42px',
                            alignItems: 'center'
                        }}>
                            <Typography fontSize="c1" color="neutral10" fontWeight="light">
                                <Link {...privacyPolicyFooterLink} />
                            </Typography>
                            <Typography fontSize="c1" color="neutral10" fontWeight="light">
                                <Link {...termsAndConditionsFooterLink} />
                            </Typography>
                            <Typography fontSize={{
                                dt: "c1"
                            }} fontWeight="light" color="neutral10">
                                <span>© Shadey {(new Date()).getFullYear()}.</span>
                                &nbsp;
                                <a href="https://atollon.com.au">Design by Atollon</a>
                            </Typography>
                        </div>
                    </div>
                </div>
                <div css={{
                    display: 'none',
                    marginTop: '16px',
                    [breakpoints.mb]: {
                        display: 'block'
                    }
                }}>
                    <Typography fontSize={{
                        dt: "c1"
                    }} fontWeight="light" css={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <div css={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}>
                            <Link {...privacyPolicyFooterLink} />
                            <div css={{
                                marginTop: '2px'
                            }}>© Shadey {(new Date()).getFullYear()}.</div>
                        </div>
                        <div css={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexDirection: 'column'
                        }}>
                            <div>
                                <Link {...termsAndConditionsFooterLink} />
                            </div>
                            <div css={{
                                marginTop: '2px'
                            }}>
                                Design by Atollon
                            </div>
                        </div>
                    </Typography>
                </div>
            </Background>
        </Container>
        {items.length > 0 && (
            <motion.div css={{
                position: 'fixed',
                left: '0',
                bottom: '44px',
                zIndex: 10,
            }} initial={{
                x: '-100%'
            }} whileInView={{
                x: '0%'
            }}>
                <button role="button" css={{
                    backgroundColor: colors.shadesWhite,
                    borderRadius: '0 10px 10px 0',
                    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.16)',
                    display: 'inline-flex',
                    padding: '16px 8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    transformOrigin: 'top left',
                    flexDirection: 'column',
                    userSelect: 'none',
                    [breakpoints.mb]: {
                        padding: '10px 5px'
                    }
                }} onClick={() => {
                    setIsOpen(true)
                }}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4002_14947)">
                            <path d="M0.255758 14C0.255757 21.5913 6.40968 27.7452 14.0009 27.7452C21.5922 27.7452 27.7461 21.5913 27.7461 14.0001C27.7461 6.4088 21.5922 0.254883 14.0009 0.254882C6.40968 0.254882 0.255758 6.4088 0.255758 14Z" fill="#BAE2F7"/>
                            <g clipPath="url(#clip1_4002_14947)">
                                <path d="M9.78348 4.00098C9.78348 4.00098 12.9451 8.3704 15.9902 9.59476H4.70666M9.78348 15.1869C9.78348 15.1869 12.9451 10.8172 15.9902 9.59307H3.99023" stroke="#1A1A1A" strokeWidth="0.77" strokeLinejoin="bevel"/>
                                <path d="M18.2068 12.5996C18.2068 12.5996 15.0452 16.969 12 18.1934H23.2836M18.2068 23.7855C18.2068 23.7855 15.0452 19.4158 12 18.1917H24" stroke="#1A1A1A" strokeWidth="0.77" strokeLinejoin="bevel"/>
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_4002_14947">
                                <rect width="28" height="28" fill="white" transform="translate(28) rotate(90)"/>
                            </clipPath>
                            <clipPath id="clip1_4002_14947">
                                <rect width="20" height="20" fill="white" transform="translate(4 4)"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <Typography css={{ transform: 'rotate(0deg)', textOrientation: 'sideways', writingMode: 'vertical-lr' }} fontSize={{ dt: "p1", mb: "p2" }} fontWeight="medium">
                        Compare ({items.length})
                    </Typography>
                </button>
            </motion.div>
        )}
        <motion.div css={{
            position: 'absolute',
            right: '0',
            bottom: '120px',
            [breakpoints.mb]: {
                bottom: '230px'
            }
        }} initial={{
            x: '100%'
        }} whileInView={{
            x: '0%'
        }}>
            <button role="button" css={{
                backgroundColor: colors.shadesWhite,
                borderRadius: '10px 0 0 10px',
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.16)',
                display: 'inline-flex',
                padding: '16px 8px',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                transformOrigin: 'top left',
                flexDirection: 'column',
                userSelect: 'none',
                [breakpoints.mb]: {
                    padding: '10px 5px'
                }
            }} onClick={() => {
                window.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: 0,
                })
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <circle cx="14" cy="14" r="14" fill="#FF5000"/>
                    <path d="M22.6486 14.9708C22.6486 14.9708 15.9109 10.0958 14.0229 5.40039V22.7989M5.3999 14.9708C5.3999 14.9708 12.1379 10.0958 14.0255 5.40039V22.7989" stroke="white" strokeLinejoin="bevel"/>
                </svg>
                <Typography css={{ transform: 'rotate(180deg)', textOrientation: 'sideways', writingMode: 'vertical-lr' }} fontSize={{ dt: "p1", mb: "p2" }} fontWeight="medium">
                    Back to top
                </Typography>
            </button>
        </motion.div>
    </footer>
  )
}

export default Footer
